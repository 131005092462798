import React from 'react'
import { graphql } from 'gatsby'
import Image from 'gatsby-image'
import Layout from '../components/layout'
import { BackgroundScrollButton } from '../components/buttons'

import './about.css'

export default function About({ data }) {
  const bannerHeader = data.bannerHeader.childImageSharp.fluid
  const bannerShadow = data.bannerShadow.childImageSharp.fluid
  const quotationMark = data.quotationMark.childImageSharp.fluid
  const rotatedQuotationMark = data.rotatedQuotationMark.childImageSharp.fluid
  return (
    <Layout>
      <div className='background-image'>
        <Image fluid={bannerHeader} className='background-image-big' />
        <h1 className='banner-header-text'>O Nas</h1>
        <Image
          fluid={bannerShadow}
          className='background-rounded-shadow'
          style={{ position: 'absolute' }}
          loading='eager'
        />
        <BackgroundScrollButton elementId='content'/>
      </div>
      <div id='content' className='about-container'>
        <div className='quotation-section'>
          <div className='paragraph quotation-text'>
            <Image fluid={quotationMark} style={{ position: 'absolute' }} className='quotation-start'/>
            <Image fluid={rotatedQuotationMark} style={{ position: 'absolute' }} className='quotation-end'/>
            <div>Moim celem było zapewnienie pacjentowi maksymalnych efektów operacji w Stambule - stolicy operacji plastycznych, z zachowaniem bezpieczeństwa osobistego i prawnego.</div>
          </div>
          <br/>
          <div className='paragraph'>
              <div>~ Martyna Zarębska</div>
              <div>Założycielka Premium Group</div>
          </div>
        </div>
        <div className='content-container hello-section'>
          <h3>Witaj w świecie piękna Premium Studio.</h3>
          <div className='text-section'>
            <h2>Kim jesteśmy?</h2>
            <p>Premium Studio jest pierwszym polskojezycznym realizatorem turystyki medycznej w Stambule. Turcja to kraj słynący na całym świecie nie tylko z pięknych krajobrazów i rzemiosła, ale także z umiejętności specjalistów plastycznych. Chcieliśmy wyjść naprzeciw oczekiwaniom naszych pacjentów, dlatego stworzyliśmy organizację, która zatrudnia dziewięciu wybitnych specjalistów plastycznych i rekonstrukcyjnych, specjalizujących się w operacjach danego typu. Cały zespół składa się z 30 osób, które dbają o komfort pacjenta przed, w trakcie i po operacji. Naszą misją jest dostarczenie pacjentom, którzy decydują się na operację za granicą, najlepszych efektów operacyjnych, w połączeniu z bezpieczeństwem. Premium wynajmuje najlepsze międzynarodowe szpitale. Bardzo selektywnie dobieramy też personel medyczny. Specjalizujemy się w operacjach rekonstrukcyjnych. Realizujmy operacje plastyczne od 2020 roku. Jesteśmy dumni z tego, że z roku na rośnie ilość naszych zadowolonych pacjentów.</p>
            <h2>Co możemy Ci zapewnić?</h2>
            <div className='paragraph'>
              <span>Z Premium Studio otrzymujesz full service, jeśli chodzi o operację wyjazdową. W ramach pakietu zapewniamy:</span>
              <ul>
                <li><b>Pełną organizację wyjazdu,</b> począwszy od dopełnienia wszystkich formalności niezbędnych przed operacją oraz związanych z nimi kosztów, poprzez zakwaterowanie w luksusowym, 5-gwiazdkowym hotelu dla Ciebie oraz osoby towarzyszącej, transfery między lotniskiem a hotelem oraz hotelem a szpitalem limuzyną, aż po osobistego wielojęzycznego opiekuna na miejscu oraz stałą opiekę rezydenta. Jednym słowem – o nic nie musisz się martwić.</li>
                <li><b>Dobór profesjonalnego, certyfikowanego specjalistę,</b> który specjalizuje się w danym typie operacji i ma na koncie co najmniej 5 tys. wykonanych operacji w danej dziedzinie. Specjalista oprócz podwójnej specjalizacji, posiada certyfikat upoważniający go do operowania pacjentów zza granicy.<br/>Specjalista zostaje przez nas wybrany indywidualnie do każdej osoby, mając na uwadze jej anatomiczne uwarunkowania i oczekiwania. Współpracujemy tylko z najlepszymi naszym zdaniem specjalistami w Turcji, którzy są najwyższej klasy specjalistami w swojej dziedzinie. Oprócz tego zapewniamy profesjonalną opiekę fizjoterapeutów i pielęgniarzy, dzięki czemu możemy zadbać o Ciebie kompleksowo i towarzyszyć Ci podczas całej metamorfozy.</li>
                <li><b>Asystę w całym procesie przed i pooperacyjnym.</b> Zanim wyjedziesz do Turcji, w Polsce odbywa się wstępna kwalifikacja. Przed każdym zabiegiem przeprowadzana jest indywidualna kwalifikacja na podstawie wysłanych zdjęć, które zostają wysłane do specjalisty, który specjalizuje się w danym zagadnieniu. Na ich podstawie specjalistaokreśli zakres i obszar operacji, przygotuje prognozę możliwych efektów i wyceni koszt zabiegu. Po samej operacji pacjent znajduje się pod opieką wykwalifikowanego personelu, a po powrocie, jeśli jest taka konieczność, zdalnie zgłasza się do Premium Studio i wysyła zdjęcia w celu konsultacji z lekarzem i przekazania dalszych zaleceń. Co więcej, zapewniamy niezbędne akcesoria pooperacyjne, a przed zabiegiem przeprowadzamy konieczne do zabiegu badania. Na żadnym etapie nie zostaniesz pozostawiony bez naszej pomocy. Nie bez powodu wszyscy nasi klienci są zadowoleni z efektów, a realizujemy setki operacji rocznie. Zaufały nam już setki pacjentów.</li>
              </ul>
            </div>
            <h2>Co wyróżnia naszą ofertę?</h2>
            <div className='paragraph'>
              <ul>
                <li><b>Jesteśmy pierwszym polskojęzycznym realizatorem turystyki medycznej w Stambule</b> - działamy na europejskich standardach – zapewniamy bezpieczeństwo osobiste oraz prawne. Nie ponosisz żadnego ryzyka decydując się z nami na operację.</li>
                <li><b>Potwierdzone doświadczenie specjalistów</b> - sprawdzony zespół najwyższej klasy specjalistów, którzy mają na swoim koncie ponad 5 tys. operacji w danej dziedzinie.</li>
                <li><b>Wybieramy specjalistę za Ciebie</b> - nie musisz szukać specjalisty na własną rękę. Na podstawie naszego bogatego doświadczenia wybierzemy specjalistę, który zapewni Ci wymarzone efekty.</li>
                <li><b>Pełna asysta podczas pobytu</b> - pomoc rezydenta i wielojęzycznego opiekuna oraz kompleksowa opieka na każdym etapie wyjazdu i zabiegu.</li>
                <li><b>Szybki czas realizacji</b> - krótkie terminy oczekiwania na operację do miesiąca czasu, dzięki kontraktom ze specjalistami.</li>
                <li><b>Połączenie operacji z egzotycznym wypoczynkiem</b> - zakwaterowanie w luksusowym, 5* hotelu, transport limuzyną, możliwość zabrania osoby towarzyszącej.</li>
                <li><b>Pewne rezultaty</b> - sprawdzona klinika, która ma 100% zadowolonych klientek.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allFile(filter: {relativeDirectory: {eq: "about"}}, sort: {order: ASC, fields: name}) {
      nodes {
        childAboutJson {
          slug
          name
          image {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          degree
          veryShortDescription
          shortDescription
        }
      }
    }
    bannerHeader: file(relativePath: {eq: "about/images/banner_header.jpeg"}) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bannerShadow: file(relativePath: {eq: "images/banner_header_shadow.png"}) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    quotationMark: file(relativePath: {eq: "about/images/quotation_mark.png"}) {
      childImageSharp {
        fluid(maxWidth: 100, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    rotatedQuotationMark: file(relativePath: {eq: "about/images/quotation_mark.png"}) {
      childImageSharp {
        fluid(maxWidth: 100, quality: 100, rotate: 180) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
